<template>
  <div :id="this.profileContainerId" style="width: 90%; height: 90%; display: flex; justify-content: center">
    <canvas :id="this.profileCanvasId" :width="widthCanvas" height="300"></canvas>
  </div>
</template>

<script>
export default {
  name: "SiloProfileVisualization",
  props: ["siloSeries", "profileSeries", "fillGrainArea", "siloMeasures"],
  data() {
    return{
      
    }
  },
  computed:{
    widthCanvas() {
      return Math.abs(this.siloSeries[0].x) <= 4 ? 330 : 
            (Math.abs(this.siloSeries[0].x) > 4 && Math.abs(this.siloSeries[0].x) <= 12 ? 400 : 
            Math.abs(this.siloSeries[0].x) > 12 && this.currentWidthDevice < 700 ? 300 : 600);
    },
    currentWidthDevice() {
      return window.innerWidth
    },
  },
  methods: {
    renderCanvas() {
      // Get the canvas context
      const siloCanvas = document.getElementById(this.profileCanvasId);
      const ctx = siloCanvas.getContext("2d");

      // Draw the grains (lines and filled)
      this.drawPolygon(ctx, this.siloSeries, this.profileSeries);
    },
    drawPolygon(ctx, siloPoints, grainsPoints) {
      let widthValue = (Math.abs(siloPoints[0].x) < 4 ? 300 : 
                      Math.abs(siloPoints[0].x) >= 4 && Math.abs(siloPoints[0].x) <= 12 ? 300 : 
                      Math.abs(siloPoints[0].x) > 12 && this.currentWidthDevice < 700 ? 300 : 500);

      let heightValue = Math.abs(siloPoints[0].x) > 12 && this.currentWidthDevice < 700 ? 100 : 200

      let adjustX = Math.abs(siloPoints[0].x) <= 4 ? 70 : 
                  (Math.abs(siloPoints[0].x) > 4 && Math.abs(siloPoints[0].x) <= 12 ? 80 : 
                  Math.abs(siloPoints[0].x) > 12 && this.currentWidthDevice < 700 ? 30 : 70);

      const adjustY = 50
      // Clear the canvas
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // Find the range of x and y coordinates for both polygons
      const xMin = Math.min(...siloPoints.map((point) => point.x));
      const xMax = Math.max(...siloPoints.map((point) => point.x));
      const yMin = Math.min(...siloPoints.map((point) => point.y));
      const yMax = Math.max(...siloPoints.map((point) => point.y));

      // Calculate scaling factors
      const xRange = (xMax - xMin);
      const yRange = (yMax - yMin);
      const xScale = (widthValue / xRange);
      const yScale = (heightValue / yRange);
      const scale = Math.min(xScale, yScale);

      // Apply scaling to points with adjustments for (0, 0) at the bottom and center
      const scaledSiloPoints = siloPoints.map((point) => ({
        x: point.x * scale + widthValue/ 2 + adjustX,
        y: heightValue - point.y * scale + adjustY,
      }));

      const scaledGrainsPoints = grainsPoints.map((point) => ({
        x: point.x * scale + widthValue/ 2 + adjustX,
        y: heightValue - point.y * scale + adjustY,
      }));

      // Draw the vertical line for measurements
      const distanceFromSilo = 10
      const baseLineX = scaledSiloPoints[0].x - distanceFromSilo;
      ctx.beginPath();
      ctx.moveTo(baseLineX, heightValue + adjustY);
      ctx.lineTo(baseLineX, adjustY);
      ctx.strokeStyle = "#13594dba";
      ctx.stroke();

      // Draw the scaled silo polygon
      ctx.beginPath();
      ctx.moveTo(scaledSiloPoints[0].x, scaledSiloPoints[0].y);

      for (let i = 1; i < scaledSiloPoints.length; i++) {
        ctx.lineTo(scaledSiloPoints[i].x, scaledSiloPoints[i].y);
      }

      ctx.closePath()

      // Draw the scaled grains polygon
      ctx.beginPath();
      ctx.moveTo(scaledGrainsPoints[0].x, scaledGrainsPoints[0].y);

      for (let i = 1; i < scaledGrainsPoints.length; i++) {
        ctx.lineTo(scaledGrainsPoints[i].x, scaledGrainsPoints[i].y);
      }

      ctx.closePath()

      // Fill the grain area if wanted for that profile
      if (this.fillGrainArea) {
        ctx.fillStyle = "#71c90dbc";
        ctx.fill();
      }

      // Draw the borders
      ctx.strokeStyle = "#13594dba";

      // Silo
      ctx.beginPath();
      ctx.moveTo(scaledSiloPoints[0].x, scaledSiloPoints[0].y);
      for (let i = 1; i < scaledSiloPoints.length; i++) {
        ctx.lineTo(scaledSiloPoints[i].x, scaledSiloPoints[i].y);
      }
      ctx.stroke();

      // Grains
      ctx.beginPath();
      ctx.moveTo(scaledGrainsPoints[0].x, scaledGrainsPoints[0].y);
      for (let i = 1; i < scaledGrainsPoints.length; i++) {
        ctx.lineTo(scaledGrainsPoints[i].x, scaledGrainsPoints[i].y);
      }
      ctx.stroke();
      
      const totalHeight = this.siloMeasures.cilinder_height + this.siloMeasures.cone_height;

      let markInterval;

      if (totalHeight <= 10) {
        markInterval = 2;
      } else if (totalHeight <= 20) {
        markInterval = 4;
      } else if (totalHeight <= 30) {
        markInterval = 6;
      } else {
        markInterval = 8;
      }

      // Tolerance to avoid overlapping numbers
      const tolerance = totalHeight <= 10 ? 1 : 
                        totalHeight <= 20 ? 2 : 
                        totalHeight <= 30 ? 3 : 4;

      ctx.fillStyle = "#000000";
      ctx.font = "12px Arial";

      for (let i = 0; i <= totalHeight; i += markInterval) {
        // remove measure if it is the same than totalheight
        if (Math.abs(totalHeight - i) <= tolerance && i !== totalHeight) {
          continue;
        }

        const y = heightValue - (i * (heightValue / totalHeight));
        const textY = y + (i === 0 ? -2 : (i === totalHeight ? 12 : 4));

        ctx.fillText(`${i} m`, scaledSiloPoints[0].x - 70, textY + scaledSiloPoints[0].y);
        ctx.beginPath();
        ctx.moveTo(scaledSiloPoints[0].x - 30, y + adjustY);
        ctx.lineTo(scaledSiloPoints[0].x - distanceFromSilo, y + adjustY);
        
        ctx.strokeStyle = "#13594dba";
        ctx.stroke();

        // Draw mid lines (half the length of main lines)
        if (i < totalHeight) {
          const midY = y - ((heightValue / totalHeight) * (markInterval / 2));
          
          ctx.beginPath();
          ctx.moveTo(scaledSiloPoints[0].x - 15, midY + adjustY);
          ctx.lineTo(scaledSiloPoints[0].x- 10, midY  + adjustY);
          ctx.strokeStyle = "#13594dba"; 
          ctx.stroke();
        }
      }

      // show measure top
      const topY = heightValue - (totalHeight * (heightValue / totalHeight));
      ctx.fillText(`${totalHeight.toFixed(1)} m`, scaledSiloPoints[0].x - 70, topY + scaledSiloPoints[0].y);
      ctx.beginPath();
      ctx.moveTo(scaledSiloPoints[0].x - 30, topY + adjustY);
      ctx.lineTo(scaledSiloPoints[0].x - distanceFromSilo, topY + adjustY);
      ctx.strokeStyle = "#13594dba";
      ctx.stroke();
    },
  },
  watch: {
    $props: {
      handler() {
        this.renderCanvas();
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    this.profileContainerId = `profile-viz-canvas-container-${Math.random()}`
    this.profileCanvasId = `silo-profile-viz-chart-${Math.random()}`
  },
  mounted() {
    this.renderCanvas();
  },
};
</script>
